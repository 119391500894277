import { Container, Typography } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import { colors } from "../../../../theme/";

// Interfaces
interface ProfileMenuModalButtonProps {
  children: string;
  innerStyle?: React.CSSProperties;
  onClick: () => void;
  style?: React.CSSProperties;
}

/**
 * Profile menu modal button component
 */
export const ProfileMenuModalButton = ({
  children,
  innerStyle,
  onClick,
  style,
}: PropsWithChildren<ProfileMenuModalButtonProps>) => {
  // useStates
  const [mouseEnter, setMouseEnter] = useState<boolean>(false);

  return (
    <Container
      onClick={onClick}
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
      style={{
        alignItems: "center",
        backgroundColor: mouseEnter ? colors.background.primary : colors.background.quinary,
        borderTopColor: colors.background.quaternary,
        borderTopStyle: "solid",
        borderTopWidth: 3,
        cursor: "pointer",
        display: "flex",
        height: 60,
        justifyContent: 'center',
        transition: 'background-color 250ms ease',
        ...style,
      }}
    >
      <Typography style={{
        color: mouseEnter ? colors.font.main : colors.font.secondary,
        fontSize: 12,
        ...innerStyle,
      }}
        onClick={onClick}>
        {children}
      </Typography>
    </Container>
  );
};