import { Area } from "react-easy-crop";

export const upper = (input: string) => input.toUpperCase();
export const capitalize = (input: string) => input.charAt(0).toUpperCase() + input.slice(1);
export const capitalizeWords = (input: string) => input.replace(/\b\w/g, (match) => match.toUpperCase());
export const objectToQueryString = (object: { [key: string]: string[]; }) => {
    const queryString = Object.entries(object)
        .map(([key, values]) => {
            if (values.length > 0) {
                return values.map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
            }
            return '';
        })
        .filter(query => query !== '')
        .join('&');

    return queryString;
};
export const readFile = (file: Blob) => new Promise<string>((resolve) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
        const result = reader.result as string;

        resolve(result);
    }, false);
    reader.readAsDataURL(file);
});
const createImage = (url: string) => new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();

    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));

    image.src = url;
});
const getRadianAngle = (degreeValue: number) => (degreeValue * Math.PI) / 180;
const rotateSize = (width: number, height: number, rotation: number) => {
    const rotRad = getRadianAngle(rotation);

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
};
export const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: Area,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) return null;

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) return null;

    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    );

    return new Promise((resolve, reject) => {
        croppedCanvas.toBlob((file) => {
            if (file) resolve(URL.createObjectURL(file));
            else reject(new Error("Failed to create blob."));
        }, 'image/jpeg');
    });
};