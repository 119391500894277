import { Container, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { apiClient } from "../../api/";
import { showSimpleSnackbarDispatcher } from "../../components/";
import { InputButton } from "../../components/InputButton";
import { InputField } from "../../components/InputField";
import { showSnackbar } from "../../components/showSnackbar";
import userIcon from "../../medias/userIcon.svg";
import { styles } from "../../theme/";
import { Page } from "../Page";

/**
 * CreateProfile page
 */
export const CreateProfile = () => {
    // Hooks
    const navigate = useNavigate();

    // useStates
    const [error, setError] = useState<boolean>(false);
    const [nickname, setNickname] = useState<string>("");

    // useMemos
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // Handlers
    const handleCreateProfile = async (token: string, provider: string) => {
        try {
            const [response, statusOk] = await apiClient.createProfile(nickname, token, provider);

            if (statusOk) {
                showSnackbar({ message: `Your profile was created.`, variant: "success" });
                navigate("/games");
            } else {
                setError(true);
                showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
            }
        } catch (error) {
            setError(true);
            showSnackbar({ message: String(error), variant: "error" });
        }
    };

    // Early return
    if (!token || !provider) return <Navigate to="/" />;

    return (
        <Page menu needsAuthentication needsVerification>
            <Container style={styles.inPlaceModal} >
                <Typography style={{ fontFamily: "ArchivoBlack", fontSize: 36 }} >New Profile</Typography>
                <InputField type="text" value={nickname} leftIcon={userIcon} leftIconSize="100%" placeholder={"Nickname"} error={error} setValue={setNickname} submitAction={() => handleCreateProfile(token, provider)} />
                <InputButton type="primary" onClick={() => handleCreateProfile(token, provider)} tooltip="Create profile">Create profile</InputButton>
            </Container>
        </Page>
    );
};