import { InputButton } from "../../../components";
import discordLogo from '../../../medias/discordLogo.svg';
import { colors } from "../../../theme";

export const DiscordButton = () => {
  return (
    <InputButton
      type={"discord"}
      tooltip="Junte-se ao nosso servidor!"
      onClick={() => window.open("https://discord.gg/veZ2WMKyD2", "_blank")}
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: "max(1em, 1.4vw)",
        justifyContent: "space-evenly",
        boxShadow: `0 0 10px 0 ${colors.trivia.discord}`,
      }}
      img={discordLogo}
      imgFilter='brightness(0) saturate(100%) invert(8%) sepia(6%) saturate(567%) hue-rotate(202deg) brightness(95%) contrast(99%)'
      alternativeHover
    >
      Junte-se ao Discord!
    </InputButton>
  );
};
