import { showSnackbar } from "./showSnackbar";

/**
 * Snackbar dispatcher for simple messages from API
 */
export const showSimpleSnackbarDispatcher = (response: { [key: string]: string[]; }) => {
    try {
        Object.entries(response).forEach(([, messages]: [string, string[]]) => {
            messages.forEach(message => showSnackbar({ message: message, variant: "warning" }));
        });
    } catch (error) {
        if (error instanceof TypeError) {
            Object.entries(
                (response as unknown) as { [key: string]: string; }
            ).forEach(([, message]: [string, string]) => {
                showSnackbar({ message: message, variant: "warning" });
            });
        }
    }
};

/**
 * Complex dispatcher for messages from API that needs treatment
 */
export const showComplexSnackbarDispatcher = (response: { [key: string]: string[]; }) => {
    try {
        Object.entries(response).forEach(([field, messages]: [string, string[]]) => {
            messages.forEach(m => {
                const message = m.includes("This field must be unique.") ? `This ${field} has been taken.` : m;

                showSnackbar({ message: message, variant: "warning" });
            });
        });
    } catch (error) {
        if (error instanceof TypeError) {
            Object.entries(
                (response as unknown) as { [key: string]: string; }
            ).forEach(([field, m]: [string, string]) => {
                const message = m.includes("This field must be unique.") ? `This ${field} has been taken.` : m;

                showSnackbar({ message: message, variant: "warning" });
            });
        }
    }
};