import { GetUserResponse } from ".";
import { API, CancelSubscriptionResponse, CreateProfileResponse, CreateSubscriptionResponse, DeletePaymentMethodResponse, DemoteInteractionCycleResponse, DiscordAuthenticateResponse, EvaluateInteractionCycleResponse, GetDiscordTokenResponse, GetInteractionCycleParamsResponse, GetInteractionCyclesResponse, GetInteractionsResponse, GetPaymentMethodsResponse, GetPaymentsResponse, GetPricesResponse, GetProductResponse, GetSubscriptionStatusResponse, GetSupportTicketGenresResponse, LogoutResponse, PromoteInteractionCycleResponse, SendInteractionToCycleResponse, SendSupportTicketResponse, UpdateUserProfileImageResponse } from "./interfaces";

class APIClient implements API {
    private async fetch<T = any>(input: string, init?: RequestInit): Promise<[T, boolean]> {
        const proxyUri = process.env.REACT_APP_BACKEND_URL ? `${process.env.REACT_APP_BACKEND_URL}/api` : "http://localhost:8000/api";
        const response = await fetch(`${proxyUri}${input}`, init);
        const json = await response.json();

        return [json, response.ok];
    };

    discordAuthenticate = async (token: string): Promise<[DiscordAuthenticateResponse, boolean]> => {
        const endpoint: string = `/discordAuthenticate/`;
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
            },
        };

        return await this.fetch<DiscordAuthenticateResponse>(endpoint, payload);
    };

    getDiscordToken = async (code: string): Promise<[GetDiscordTokenResponse, boolean]> => {
        const endpoint: string = `/getDiscordToken/?code=${code}`;
        const payload: RequestInit = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        return await this.fetch<GetDiscordTokenResponse>(endpoint, payload);
    };

    createProfile = async (nickname: string, token: string, provider: string): Promise<[CreateProfileResponse, boolean]> => {
        const endpoint: string = "/createProfile/";
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
            body: JSON.stringify({ nickname: nickname }),
            method: "POST",
        };

        return await this.fetch<CreateProfileResponse>(endpoint, payload);
    };

    logout = async (token: string, provider: string): Promise<[LogoutResponse, boolean]> => {
        const endpoint: string = "/logout/";
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
            method: "POST",
        };

        return await this.fetch<LogoutResponse>(endpoint, payload);
    };

    getSubscriptionStatus = async (token: string, provider: string, product: string): Promise<[GetSubscriptionStatusResponse, boolean]> => {
        const endpoint: string = `/getSubscriptionStatus/${product}/`;
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
        };

        return await this.fetch<GetSubscriptionStatusResponse>(endpoint, payload);
    };

    cancelSubscription = async (token: string, provider: string, product: string): Promise<[CancelSubscriptionResponse, boolean]> => {
        const endpoint: string = `/cancelSubscription/${product}/`;
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
            method: "POST",
        };

        return await this.fetch<CancelSubscriptionResponse>(endpoint, payload);
    };

    demoteInteractionCycle = async (token: string, provider: string, interactionId: number): Promise<[DemoteInteractionCycleResponse, boolean]> => {
        const endpoint: string = `/demoteInteractionCycle/${interactionId}/`;
        const payload: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
                "Provider": provider,
            },
            method: "POST",
        };

        return await this.fetch<DemoteInteractionCycleResponse>(endpoint, payload);
    };

    createSubscription = async (token: string, provider: string, name: string, paymentMethodId: string, product: string, priceId: string): Promise<[CreateSubscriptionResponse, boolean]> => {
        const endpoint: string = "/createSubscription/";
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
            body: JSON.stringify({
                name: name,
                payment_method: paymentMethodId,
                product: product,
                price_id: priceId,
            }),
            method: "POST",
        };

        return await this.fetch<CreateSubscriptionResponse>(endpoint, payload);
    };

    deletePaymentMethod = async (token: string, provider: string, payment_method_id: string): Promise<[DeletePaymentMethodResponse, boolean]> => {
        const endpoint: string = `/deletePaymentMethod/${payment_method_id}/`;
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
            method: "POST",
        };

        return await this.fetch<DeletePaymentMethodResponse>(endpoint, payload);
    };

    evaluateInteractionCycle = async (
        token: string, provider: string,
        interactionId: number,
        status: "EBI" | "SBA" | "SBD" | "SBF" | "TBE",
        fixed_answer?: string,
        fixed_question?: string,
        exposure?: "PUB" | "PRV"
    ): Promise<[EvaluateInteractionCycleResponse, boolean]> => {
        const endpoint: string = `/evaluateInteractionCycle/${interactionId}/`;
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
            body: JSON.stringify({
                exposure: exposure ?? "PUB",
                fixed_answer: fixed_answer,
                fixed_question: fixed_question,
                status: status,
            }),
            method: "POST",
        };

        return await this.fetch<EvaluateInteractionCycleResponse>(endpoint, payload);
    };

    getInteractionCycleParams = async (
        token: string,
        provider: string,
        status: ("EBI" | "SBA" | "SBD" | "SBF" | "TBE")[],
        exposure: "PUB" | "PRV",
    ): Promise<[GetInteractionCycleParamsResponse, boolean]> => {
        const endpoint: string = "/getInteractionCycleParams/";
        const queryParams: string = status.map((s) => `status=${s}`).join("&");
        let query: string = queryParams.length > 0 ? `?${queryParams}` : "";
        query += exposure ? `&exposure=${exposure}` : "";

        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
        };

        return await this.fetch<GetInteractionCycleParamsResponse>(endpoint + query, payload);
    };


    getPaymentMethods = async (token: string, provider: string): Promise<[GetPaymentMethodsResponse, boolean]> => {
        const endpoint: string = "/getPaymentMethods/";
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
        };

        return await this.fetch<GetPaymentMethodsResponse>(endpoint, payload);
    };


    getPayments = async (token: string, provider: string): Promise<[GetPaymentsResponse, boolean]> => {
        const endpoint: string = "/getPayments/";
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
        };

        return await this.fetch<GetPaymentsResponse>(endpoint, payload);
    };

    getProduct = async (product: string): Promise<[GetProductResponse, boolean]> => {
        const endpoint: string = `/getProduct/${product}/`;
        const payload: RequestInit = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        return await this.fetch<GetProductResponse>(endpoint, payload);
    };

    getPrices = async (product: string): Promise<[GetPricesResponse, boolean]> => {
        const endpoint: string = `/getPrices/${product}/`;
        const payload: RequestInit = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        return await this.fetch<GetPricesResponse>(endpoint, payload);
    };

    getInteractionCycles = async (
        token: string, provider: string,
        status: ("EBI" | "SBA" | "SBD" | "SBF" | "TBE")[],
        exposure: "PUB" | "PRV",
        additional_query?: string
    ): Promise<[GetInteractionCyclesResponse, boolean]> => {
        const endpoint: string = "/getInteractionCycles/";
        const queryParams: string = status.map((s) => `status=${s}`).join("&");
        let query: string = queryParams.length > 0 ? `?${queryParams}` : "";
        query += exposure ? `&exposure=${exposure}` : "";
        query += additional_query ? `&${additional_query}` : "";

        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
        };

        return await this.fetch<GetInteractionCyclesResponse>(endpoint + query, payload);
    };

    getInteractions = async (token: string, provider: string, sessionId: string): Promise<[GetInteractionsResponse, boolean]> => {
        const endpoint: string = `/getInteractions/${sessionId}/`;
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
        };

        return await this.fetch<GetInteractionsResponse>(endpoint, payload);
    };

    getSupportTicketGenres = async (token: string, provider: string): Promise<[GetSupportTicketGenresResponse, boolean]> => {
        const endpoint: string = "/getSupportTicketGenres/";
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
        };

        return await this.fetch<GetSupportTicketGenresResponse>(endpoint, payload);
    };

    getUser = async (token: string, provider: string): Promise<[GetUserResponse, boolean]> => {
        const endpoint: string = "/getUser/";
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
        };

        return await this.fetch<GetUserResponse>(endpoint, payload);
    };

    promoteInteractionCycle = async (token: string, provider: string, interactionId: number, fixed_answer?: string, fixed_question?: string): Promise<[PromoteInteractionCycleResponse, boolean]> => {
        const endpoint: string = `/promoteInteractionCycle/${interactionId}/`;
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
            body: JSON.stringify({
                fixed_answer: fixed_answer,
                fixed_question: fixed_question,
            }),
            method: "POST",
        };

        return await this.fetch<PromoteInteractionCycleResponse>(endpoint, payload);
    };

    sendInteractionToCycle = async (token: string, provider: string, interactionId: number, evaluation: "R" | "N" | "A"): Promise<[SendInteractionToCycleResponse, boolean]> => {
        const endpoint: string = `/sendInteractionToCycle/${interactionId}/`;
        const payload: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
                "Provider": provider,
            },
            body: JSON.stringify({ evaluation: evaluation }),
            method: "POST",
        };

        return await this.fetch<SendInteractionToCycleResponse>(endpoint, payload);
    };

    sendSupportTicket = async (token: string, provider: string, genre: string, message: string, page: string): Promise<[SendSupportTicketResponse, boolean]> => {
        const endpoint: string = "/sendSupportTicket/";
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
            body: JSON.stringify({ genre: genre, message: message, page: page }),
            method: "POST",
        };

        return await this.fetch<SendSupportTicketResponse>(endpoint, payload);
    };

    updateUserProfileImage = async (token: string, provider: string, formData: FormData): Promise<[UpdateUserProfileImageResponse, boolean]> => {
        const endpoint: string = "/updateUserProfileImage/";
        const payload: RequestInit = {
            headers: {
                "Authorization": token,
                "Content-Type": "application/json",
                "Provider": provider,
            },
            body: formData,
            method: "POST",
        };

        return await this.fetch<UpdateUserProfileImageResponse>(endpoint, payload);
    };
};

export const apiClient = new APIClient();
