import styled from "@emotion/styled";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { colors } from "../theme";

/**
 * Custom tooltip component
 */
export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.background.main,
        color: colors.font.main,
        fontSize: 11,
    },
}));
