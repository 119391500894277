import { FormControlLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import searchIcon from '../../medias/searchIcon.svg';
import { colors, styles } from "../../theme";
import { capitalize } from '../../utils';
import { Group } from "../Group";
import { InputField } from "../InputField";
import { CustomRadio } from "./CustomRadio";


// Interfaces
interface FilterCardProps {
    queryKey: string;
    queryValues: string[];
    setSelectedParams: React.Dispatch<React.SetStateAction<{ [key: string]: string[]; } | undefined>>;
}

/**
 * Filter card component
 */
export const FilterCard = ({ queryKey, queryValues, setSelectedParams }: FilterCardProps) => {
    // useStates
    const [filterValue, setFilterValue] = useState<string>("");
    const [values, setValues] = useState<string[]>(queryValues);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    // useEffects
    useEffect(() => setValues(queryValues.filter(queryValue => queryValue.toLowerCase().includes(filterValue.toLowerCase()))), [filterValue, queryValues]);

    return (
        <div style={{
            borderBottom: `1px solid ${colors.background.quinary}`,
            maxHeight: "30vh",
            paddingBottom: 20,
            paddingRight: 20,
        }}>
            <Typography style={{ marginBottom: 10, marginLeft: 10, fontWeight: "bold" }}>{capitalize(queryKey)}</Typography>
            <InputField
                placeholder="Search"
                rightIcon={searchIcon}
                rightIconSize={20}
                setValue={setFilterValue}
                type="text"
                value={filterValue}
                style={{
                    background: colors.background.primary,
                    height: 65,
                    fontFamily: "PublicSansThin",
                }}
            />

            <Group gap={5} direction={"column"} overflow style={styles.radioGroup}>
                {values.length > 0
                    ? values.map(value => <FormControlLabel
                        key={value}
                        style={styles.radio}
                        value={value}
                        control={
                            <CustomRadio
                                queryKey={queryKey}
                                selectedValues={selectedValues}
                                setSelectedParams={setSelectedParams}
                                setSelectedValues={setSelectedValues}
                                value={value}
                            />
                        }
                        label={value}
                    />)
                    : <Typography style={{ marginTop: 20, marginBottom: 10, fontWeight: "bold" }}>No {queryKey}s.</Typography>
                }
            </Group>
        </div>
    );
};