import { Modal, Paper } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { colors } from "../theme";

// Interfaces
interface OverlayFixedModalProps {
    handleClose: () => void;
    open: boolean;
    style?: React.CSSProperties;
}


/**
 * Overlay fixed modal component
 */
export const OverlayFixedModal = ({ children, handleClose, open, style }: PropsWithChildren<OverlayFixedModalProps>) => {
    return (
        <Modal
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            disableAutoFocus
            onClose={handleClose}
            open={open}
        >
            <Paper style={{
                background: colors.background.quaternary,
                borderRadius: 15,
                overflow: "auto",
                padding: 0,
                position: "fixed",
                right: 70,
                top: "11%",
                width: 200,
                zIndex: 1301,
                ...style,
            }}>
                {children}
            </Paper>
        </Modal >
    );
};
