import { Icon, IconButton, InputBase, Paper } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import closedEyeIcon from '../medias/closedEyeIcon.svg';
import eyeIcon from '../medias/eyeIcon.svg';
import warningIcon from "../medias/warningIcon.svg";
import { colors, styles } from "../theme";

// Interfaces
interface InputFieldProps {
    ariaLabel?: string;
    error?: boolean;
    innerStyle?: React.CSSProperties;
    leftIcon?: string;
    leftIconSize?: number | string;
    maxLength?: number;
    multiline?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => boolean;
    placeholder?: string;
    rightIcon?: string;
    rightIconSize?: number;
    setError?: Dispatch<SetStateAction<boolean>>;
    setValue: Dispatch<SetStateAction<string>>;
    style?: React.CSSProperties;
    submitAction?: Function;
    type?: "text" | "date" | "email" | "password";
    value: string;
}

/**
 * Input field component
 */
export const InputField = ({
    ariaLabel,
    error,
    innerStyle,
    leftIcon,
    leftIconSize,
    maxLength,
    multiline,
    onChange,
    placeholder,
    rightIcon,
    rightIconSize,
    setError,
    setValue,
    style,
    submitAction,
    type,
    value,
}: InputFieldProps) => {
    const typeIsPassword = type === "password";

    // useStates
    const [isVisible, setIsVisible] = useState<boolean>(false);

    // useEffects
    useEffect(() => {
        if (value && maxLength && value.length > maxLength) setValue(value.slice(0, maxLength));
    }, [maxLength, setValue, value]);

    // Handlers
    const handleOnKeyDown = (element: { key: string; preventDefault: () => void; }) => {
        if (element.key === 'Enter') {
            if (submitAction) submitAction();
            return element.preventDefault();
        }
    };
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (onChange) {
            const result = onChange(event);

            if (setError) setError(!result);
        }

        if (setValue) setValue(event.target.value);
    };

    const inputFieldStyle: React.CSSProperties = {
        ...styles.inputField,
        ...style,
    };

    return (
        <Paper component="form" style={inputFieldStyle}>
            {value && typeIsPassword
                ? <IconButton style={{ display: "flex", alignItems: "center", padding: "10px 20px", marginRight: 5 }} onClick={() => setIsVisible(!isVisible)}>
                    <img style={{ height: leftIconSize ?? "80%" }} src={isVisible ? eyeIcon : closedEyeIcon} alt={`View password icon for ${ariaLabel ?? placeholder}`} />
                </IconButton>
                : leftIcon &&
                <Icon style={{ display: "flex", padding: "10px 25px", alignItems: "center" }}>
                    <img style={{ height: leftIconSize ?? "80%" }} src={leftIcon} alt={`Left icon for ${ariaLabel ?? placeholder}`} />
                </Icon>
            }
            <InputBase
                autoComplete="on"
                disabled={setValue === undefined}
                inputProps={ariaLabel ? { 'aria-label': ariaLabel } : undefined}
                maxRows={multiline ? 5 : undefined}
                minRows={multiline ? 5 : undefined}
                multiline={multiline}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                placeholder={placeholder}
                type={(typeIsPassword && isVisible) ? "" : type}
                value={value}
                style={{
                    color: colors.font.main,
                    flex: 1,
                    paddingBottom: multiline ? 20 : undefined,
                    paddingLeft: leftIcon ? 0 : 25,
                    paddingRight: rightIcon ? 0 : 25,
                    paddingTop: multiline ? 20 : undefined,
                    ...innerStyle,
                }}
            />
            {(rightIcon || error) &&
                < Icon style={{ display: "flex", alignItems: "center", padding: "10px 25px" }}>
                    <img style={{ height: rightIconSize ?? "100%" }} src={error ? warningIcon : rightIcon} alt={`Right icon for ${ariaLabel ?? placeholder}`} />
                </Icon>
            }
        </Paper >
    );
};