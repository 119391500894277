import { Container } from "@mui/material";
import { useMemo, useState } from "react";
import { Interaction, InteractionCycle, apiClient } from "../../../../api/";
import {
    Chat,
    Group,
    InputButton,
    OverlayModal,
    ShowTextField,
    showSimpleSnackbarDispatcher,
    showSnackbar,
} from "../../../../components/";
import { colors, styles } from "../../../../theme/";
import { InteractionText } from "./InteractionText";

// Interfaces
interface InteractionCycleCardProps {
    handleShouldBeFixed: () => void;
    interactionCycle: InteractionCycle;
    setInteractionCycles: React.Dispatch<React.SetStateAction<InteractionCycle[]>>;
}

/**
 * Interaction cycle card component
 */
export const InteractionCycleCard = ({ handleShouldBeFixed, interactionCycle, setInteractionCycles }: InteractionCycleCardProps) => {
    const {
        evaluation,
        interaction: {
            id: interactionId,
            answer,
            question,
        },
        status,
    } = interactionCycle;
    const borderColor = () => {
        switch (evaluation) {
            case "A": return colors.background.success;
            case "R": return colors.background.error;
            default: return colors.background.main;
        }
    };

    // useStates
    const [, setCriticalError] = useState<boolean>(false);
    const [, setError] = useState<boolean>(false);
    const [checkContextLoading, setCheckContextLoading] = useState<boolean>(false);
    const [contextModalOpen, setContextModalOpen] = useState<boolean>(false);
    const [interactions, setInteractions] = useState<Interaction[]>([]);
    const [updateStatusLoading, setUpdateStatusLoading] = useState<boolean>(false);

    // useMemos
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // Handlers - Update status
    const handleUpdateStatus = async (status: "EBI" | "SBA" | "SBD" | "SBF" | "TBE") => {
        // Early return
        if (!token || !provider) return;

        setUpdateStatusLoading(true);

        try {
            const [response, statusOk] = await apiClient.evaluateInteractionCycle(
                token,
                provider,
                interactionId,
                status,
            );

            if (statusOk && response) {
                const updatedInteraction = { ...interactionCycle, status: status };

                setInteractionCycles(prev => prev.map(i => i.interaction.id === interactionId ? updatedInteraction : i));
                showSnackbar({ message: `Interaction evaluation sent.`, variant: "success" });
            } else {
                setError(true);
                showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
            }
        } catch (error) {
            setCriticalError(true);
            showSnackbar({ message: String(error), variant: "error" });
        }

        setUpdateStatusLoading(false);
    };

    // Handlers - Check context
    const handleCheckContext = async () => {
        // Early return
        if (!token || !provider) return;

        setCheckContextLoading(true);

        const sessionId = interactionCycle.interaction.session;

        try {
            const [response, statusOk] = await apiClient.getInteractions(
                token,
                provider,
                sessionId,
            );

            if (statusOk && response && response.result) {
                setContextModalOpen(true);
                setInteractions(response.result);
                showSnackbar({ message: `Context for session ${sessionId} opened.`, variant: "success" });
            } else {
                setError(true);
                showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
            }
        } catch (error) {
            setCriticalError(true);
            showSnackbar({ message: String(error), variant: "error" });
        }

        setCheckContextLoading(false);
    };

    return (
        <>
            <Container style={{
                ...styles.inPlaceModal,
                borderColor: borderColor(),
                borderStyle: "solid",
                borderWidth: 2,
                maxWidth: 1080,
            }} >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                }}>
                    <InputButton
                        disabled={checkContextLoading}
                        onClick={handleCheckContext}
                        style={{ width: 200 }}
                        tooltip="Checar contexto"
                        type="main"
                    >
                        Checar contexto
                    </InputButton>
                </div>
                <hr style={{ width: "100%", borderColor: colors.background.secondary }} />
                <Group direction="row">
                    <InteractionText title="Input">
                        {question}
                    </InteractionText>
                    <InteractionText title="Output">
                        {answer}
                    </InteractionText>
                </Group>
                <hr style={{ width: "100%", borderColor: colors.background.secondary }} />
                {status === "TBE"
                    ? (
                        <Group direction="row">
                            <InputButton disabled={updateStatusLoading} type="secondary" onClick={() => handleUpdateStatus("SBD")} tooltip="Propor deleção">Deve ser deletado</InputButton>
                            <InputButton disabled={updateStatusLoading} type="main" onClick={handleShouldBeFixed} tooltip="Propor mudança ou privacidade">Deve ser consertado ou privado</InputButton>
                            <InputButton disabled={updateStatusLoading} type="primary" onClick={() => handleUpdateStatus("SBA")} tooltip="Aprovar interação">Parece bom</InputButton>
                        </Group>
                    )
                    : <ShowTextField>Interação avaliada! Obrigado!</ShowTextField>
                }
            </Container>
            <OverlayModal
                handleClose={() => setContextModalOpen(false)}
                open={contextModalOpen}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    height: "100%",
                    justifyContent: "space-between",
                }}>
                    <Chat activeInteractionId={interactionId} interactions={interactions} setInteractions={setInteractions} />
                    <InputButton type="main" onClick={() => setContextModalOpen(false)} tooltip="Fechar contexto">Fechar</InputButton>
                </div>
            </OverlayModal>
        </>
    );
};
