import { Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiClient } from "../../../api/";
import {
    InputButton,
    InputField,
    InputSelect,
    OverlayFixedModal,
    showComplexSnackbarDispatcher,
    showSnackbar,
} from "../../../components/";
import { colors, styles } from "../../../theme/";
import { CriticalError } from "../../CriticalError";

// Interfaces
interface SupportTicketProps {
    revertSupportTicket?: boolean;
};

/**
 * Support component
 */
export const Support = ({ revertSupportTicket }: SupportTicketProps) => {
    // Hooks
    const location = useLocation();

    // useStatus
    const [criticalError, setCriticalError] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [genre, setGenre] = useState<string>("");
    const [genres, setGenres] = useState<string[]>([]);
    const [message, setMessage] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);

    // useMemos
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // useEffects
    useEffect(() => {
        const getSupportTicketGenres = async (token: string, provider: string) => {
            try {
                const [response, statusOk] = await apiClient.getSupportTicketGenres(token, provider);

                if (statusOk && response.result) {
                    setGenres(response.result);
                } else {
                    setError(true);
                    showComplexSnackbarDispatcher(response as { [key: string]: string[]; });
                }

            } catch (error) {
                setCriticalError(true);
                showSnackbar({ message: String(error), variant: "error" });

            }
        };

        if (token && provider) {
            getSupportTicketGenres(token, provider);
        } else {
            setError(true);
            setGenres([]);
        }

    }, [provider, token]);

    // Handlers
    const handleReport = async () => {
        if (!token || !provider || !genre || !message) return;

        try {
            const page = location.pathname;
            const [response, statusOk] = await apiClient.sendSupportTicket(token, provider, genre, message, page);

            if (statusOk) {
                showSnackbar({ message: `Thank you for your support!`, variant: "success" });
                setOpen(false);
            } else {
                showComplexSnackbarDispatcher(response as { [key: string]: string[]; });
            }

        } catch (error) {
            setError(true);
            showSnackbar({ message: String(error), variant: "error" });
        }
    };

    // Early return
    if (criticalError) return <CriticalError />;
    if (!token) return null;

    const supportModal = {
        ...styles.supportModal,
        right: revertSupportTicket ? undefined : 20,
        left: revertSupportTicket ? 20 : undefined,
    };

    return (
        <>
            <div style={{
                bottom: 20,
                display: "flex",
                left: revertSupportTicket ? 20 : undefined,
                position: "fixed",
                right: revertSupportTicket ? undefined : 20,
                zIndex: 1301,
            }}>
                <InputButton
                    onClick={open ? handleReport : () => setOpen(true)}
                    tooltip="Enviar ticket de suporte"
                    type={open ? "primary" : "main"}
                    disabled={open && (!message || !genre)}
                >
                    {open ? "Enviar ticket" : "Suporte"}
                </InputButton>
            </div >
            <OverlayFixedModal handleClose={() => setOpen(false)} open={open} style={supportModal}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: 20,
                    alignItems: "center",
                }}>
                    <Typography style={{ fontFamily: "ArchivoBlack", fontSize: 24, color: colors.font.main }} >Solicitar Suporte</Typography>
                    <InputSelect fields={genres} value={genre} placeholder="Selecione o problema" setValue={setGenre} error={error} />
                    <InputField placeholder={"Mensagem"} value={message} setValue={setMessage} multiline />
                </div>
            </OverlayFixedModal>
        </>
    );
};