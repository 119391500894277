import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../api";
import { showComplexSnackbarDispatcher, showSnackbar } from "../../../components";
import { useSearchParams } from "../../../hooks";
import { CriticalError } from "../../CriticalError";
import { Loading } from "../../Loading";


export const GetDiscordToken = () => {
    // Hooks
    const navigate = useNavigate();
    const searchParams = useSearchParams();

    // useStates
    const [criticalError, setCriticalError] = useState<boolean>(false);

    // useEffects - Get Discord token
    useEffect(() => {
        const code = searchParams.get("code");

        if (!code) return;

        const getDiscordToken = async (code: string) => {
            try {
                const [getDiscordTokenResponse, getDiscordTokenStatusOk] = await apiClient.getDiscordToken(code);

                if (getDiscordTokenStatusOk && getDiscordTokenResponse.result) {
                    const { access_token, token_type } = getDiscordTokenResponse.result;

                    localStorage.setItem("provider", "discord");
                    localStorage.setItem("token", `${token_type} ${access_token}`);
                    showSnackbar({ message: "Successfully logged in with Discord", variant: "success" });

                    try {
                        const [response, statusOk] = await apiClient.discordAuthenticate(`${token_type} ${access_token}`);

                        if (statusOk && response.profiles) {
                            const { profiles } = response;

                            if (profiles.length === 0) {
                                navigate("/createProfile");
                            } else {
                                showSnackbar({ message: "Successfully loaded profile", variant: "success" });
                                navigate("/");
                            }

                        } else {
                            setCriticalError(true);
                            showComplexSnackbarDispatcher(response as { [key: string]: string[]; });
                        }
                    } catch (error) {
                        setCriticalError(true);
                        showSnackbar({ message: String(error), variant: "error" });
                    }
                } else {
                    setCriticalError(true);
                    showComplexSnackbarDispatcher(getDiscordTokenResponse as { [key: string]: string[]; });
                }
            } catch (error) {
                setCriticalError(true);
                showSnackbar({ message: String(error), variant: "error" });
            }
        };

        getDiscordToken(code);

    }, [navigate, searchParams]);

    // Early return
    if (criticalError) return <CriticalError />;

    return <Loading />;
};