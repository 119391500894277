import { Button } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { colors, styles } from "../theme/";
import { CustomTooltip } from "./CustomTooltip";

// Interfaces
interface ButtonProps {
    alternativeHover?: boolean;
    disabled?: boolean;
    img?: string;
    imgFilter?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    tooltip?: string,
    type: "main" | "primary" | "secondary" | "disabled" | "discord";
}

/**
 * Input button component
 */
export const InputButton = ({
    children,
    alternativeHover,
    disabled,
    img,
    imgFilter,
    onClick,
    style,
    tooltip,
    type,
}: PropsWithChildren<ButtonProps>) => {
    // useStates
    const [hover, setHover] = useState<boolean>(false);

    // Handlers
    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);

    // useEffects
    useEffect(() => {
        if (disabled) setHover(false);
    }, [disabled]);

    const buttonColor = colors.button[
        disabled
            ? "disabled"
            : hover
                ? alternativeHover
                    ? "alternativeHover"
                    : "hover"
                : type];
    const buttonStyle = {
        ...styles.button,
        background: buttonColor.background,
        color: buttonColor.font,
        fontFamily: type === "main" ? "PublicSansThin" : "PublicSansRegular",
        ...style,
    };

    // Early return
    if (disabled) return <Button
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={buttonStyle}
        variant="contained"
    >
        {children}
    </Button>;

    return (
        <CustomTooltip title={tooltip ?? `To ${children}`}>
            <Button
                onClick={onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={buttonStyle}
                variant="contained"
            >
                {img && <img
                    src={img}
                    alt="Discord logo"
                    style={{
                        height: "80%",
                        filter: hover
                            ? alternativeHover
                                ? "brightness(0) saturate(100%) invert(89%) sepia(67%) saturate(0%) hue-rotate(296deg) brightness(102%) contrast(104%)"
                                : "brightness(0) saturate(100%) invert(48%) sepia(9%) saturate(454%) hue-rotate(194deg) brightness(95%) contrast(86%)"
                            : imgFilter,
                    }}
                />}
                {children}
            </Button>
        </CustomTooltip>
    );
};