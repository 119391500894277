import { Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../api/";
import { Group } from "../../../components/Group";
import { InputButton } from "../../../components/InputButton";
import { OverlayFixedModal } from "../../../components/OverlayFixedModal";
import { ShowTextField } from "../../../components/ShowTextField";
import { showSnackbar } from "../../../components/showSnackbar";
import { showSimpleSnackbarDispatcher } from "../../../components/showSnackbarDispatcher";
import { colors, styles } from "../../../theme/";

// Interfaces
interface UnsubscribeButtonProps {
    productId: string;
    productName: string;
}

/**
 * Unsubscribe button component
 */
export const UnsubscribeButton = ({ productId, productName }: UnsubscribeButtonProps) => {
    // Hooks
    const navigate = useNavigate();

    // useStatus
    const [open, setOpen] = useState<boolean>(false);

    // useMemos
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // Handlers - Unsubscribe
    const handleUnsubscribe = async () => {
        const unsubscribe = async (token: string, provider: string, product: string) => {
            try {
                const [response, statusOk] = await apiClient.cancelSubscription(token, provider, product);

                if (!statusOk) {
                    showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
                    return;
                }

                showSnackbar({ message: "Subscription successfully cancelled!", variant: "success" });
                window.location.reload();

            } catch (error) {
                showSnackbar({ message: String(error), variant: "error" });
            }
        };

        if (!token || !provider) { navigate("/"); return; }

        try {
            unsubscribe(token, provider, productId);
        } catch (error) {
            showSnackbar({ message: String(error), variant: "error" });
        }
    };

    return (
        <>
            <InputButton type="secondary" onClick={() => setOpen(true)} tooltip="Cancelar inscrição" style={{ padding: "40px 0px" }}>Cancelar Inscrição</InputButton>
            <OverlayFixedModal handleClose={() => setOpen(false)} open={open} style={styles.productModal}>
                <div style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    <Typography style={{ fontFamily: "ArchivoBlack", fontSize: 24, color: colors.font.main }} >
                        Cancelar
                    </Typography>
                    <ShowTextField>
                        <Typography>
                            Você está prestes a cancelar seu plano '{productName}''. Isto significa que você perderá acesso a todos os recursos do produto no final do seu ciclo de faturamento atual.
                        </Typography>
                        <Typography style={{
                            fontSize: 20,
                            fontWeight: "bold",
                        }}>
                            Tem certeza que deseja cancelar seu plano?
                        </Typography>
                    </ShowTextField>
                    <Group direction={"row"}>
                        <InputButton
                            onClick={handleUnsubscribe}
                            tooltip="Cancelar inscrição"
                            type="secondary"
                        >
                            Cancelar inscrição
                        </InputButton>
                        <InputButton
                            onClick={() => setOpen(false)}
                            tooltip="Preservar inscrição"
                            type="primary"
                        >
                            Preservar inscrição
                        </InputButton>
                    </Group>
                </div>
            </OverlayFixedModal>
        </>
    );
};