import { StripeElementStyle } from "@stripe/stripe-js";
import React from "react";
import { colors } from "./colors";

interface Styles {
    attentionModal: React.CSSProperties;
    button: React.CSSProperties;
    cardElement: StripeElementStyle;
    chatSessionModal: React.CSSProperties;
    content: React.CSSProperties;
    criticalErrorPage: React.CSSProperties;
    inPlaceModal: React.CSSProperties;
    inputField: React.CSSProperties;
    inputSelect: React.CSSProperties;
    inputSelectInner: React.CSSProperties;
    label: React.CSSProperties;
    link: React.CSSProperties;
    loadingPage: React.CSSProperties;
    menu: React.CSSProperties;
    needsEmailVerification: React.CSSProperties;
    overlayModal: React.CSSProperties;
    page: React.CSSProperties;
    productModal: React.CSSProperties;
    profileMenu: React.CSSProperties;
    profileMenuAvatar: React.CSSProperties;
    profileMenuModal: React.CSSProperties;
    radio: React.CSSProperties;
    radioGroup: React.CSSProperties;
    searchField: React.CSSProperties;
    supportModal: React.CSSProperties;
    title: React.CSSProperties;
}


export const border_dev = "0px solid #FFFFFF";
export let borderRadius = "15px";
// export let fontFamily = "Comic Sans MS, Comic Sans, cursive";

export const styles: Styles = {
    // Pages
    page: {
        alignItems: "center",
        background: colors.background.main,
        color: colors.font.main,
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    content: {
        alignItems: "center",
        background: colors.background.main,
        color: colors.font.main,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "100%",
    },
    loadingPage: {
        alignItems: "center",
        backgroundColor: colors.background.primary,
        bottom: 0,
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,
        zIndex: 9999,
    },
    needsEmailVerification: {
        background: colors.background.secondary,
        height: "100vh",
    },
    criticalErrorPage: {
        background: colors.background.secondary,
        height: "100vh",
    },

    // Modals
    attentionModal: {
        alignItems: 'center',
        background: colors.background.quaternary,
        borderRadius: 15,
        left: "50.3%",
        maxHeight: "50vw",
        minWidth: 300,
        overflow: "hidden",
        padding: 25,
        position: "relative",
        top: "50%",
        transform: 'translate(-50%, -50%)',
        width: "33vw",
        zIndex: 1301,
    },
    chatSessionModal: {
        background: colors.background.quaternary,
        borderRadius: 15,
        bottom: "11%",
        overflow: "auto",
        padding: 25,
        position: "fixed",
        right: 20,
        top: undefined,
        width: 500,
        zIndex: 1301,
    },
    inPlaceModal: {
        alignItems: "center",
        backgroundColor: colors.background.primary,
        borderRadius: 30,
        boxShadow: `0px 0px 10px ${colors.background.primary}`,
        display: "flex",
        flexDirection: "column",
        gap: 25,
        justifyContent: "center",
        maxWidth: 650,
        padding: "50px 60px",
    },
    productModal: {
        alignItems: 'center',
        background: colors.background.quaternary,
        borderRadius: 15,
        left: "50.3%",
        maxHeight: "50vw",
        minWidth: 300,
        padding: 25,
        position: "relative",
        top: "50%",
        transform: 'translate(-50%, -50%)',
        width: "33vw",
        zIndex: 1301,
    },
    profileMenuModal: {
        background: colors.background.quaternary,
        borderRadius: 15,
        overflow: "auto",
        padding: 0,
        position: "fixed",
        right: 70,
        top: "11%",
        width: 200,
        zIndex: 1301,
    },
    supportModal: {
        background: colors.background.quaternary,
        borderRadius: 15,
        overflow: "auto",
        padding: 25,
        position: "fixed",
        right: 20,
        bottom: "11%",
        width: 350,
        zIndex: 1301,
        top: "50%",
    },
    overlayModal: {},

    // Inputs

    cardElement: {
        base: {
            color: colors.font.main,
            fontSize: "18px",

            "::placeholder": {
                color: "#858585",
            },
        },
        invalid: {
            color: colors.font.error,
            iconColor: colors.font.error,
        },
    },
    inputField: {
        alignItems: 'center',
        background: colors.background.main,
        borderRadius: 15,
        display: 'flex',
        minHeight: 70,
        width: "100%"
    },
    inputSelect: {
        background: colors.background.main,
        borderRadius: 15,
        color: colors.font.main,
        height: 70,
        width: "100%",
    },
    inputSelectInner: {
        background: colors.background.main,
        borderRadius: 15,
        color: colors.font.main,
        marginTop: 5,
        padding: 0,
    },
    radio: {
        alignItems: 'center',
        background: colors.background.primary,
        borderRadius: 15,
        color: colors.font.primary,
        display: 'flex',
        fontFamily: "PublicSansThin",
        height: 45,
        margin: 0,
        maxHeight: 45,
        padding: '2px 4px',
        width: "80%",
    },
    radioGroup: {
        alignItems: 'center',
        display: 'flex',
        marginTop: 10,
        maxHeight: "20vh",
        width: "100%",
    },
    menu: {
        alignItems: "center",
        alignSelf: "flex-start",
        background: `linear-gradient(0deg, ${colors.background.main} 0%, ${colors.background.secondary} 100%)`,
        boxShadow: `0 0 10px 0 ${colors.background.secondary}`,
        color: colors.font.main,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        maxWidth: "100%",
        minHeight: "11vh",
        padding: "0px 40px",
        position: "sticky",
        top: 0,
        zIndex: 1000,
    },
    searchField: {
        alignItems: 'center',
        background: colors.background.quaternary,
        borderRadius: 15,
        display: 'flex',
        height: 55,
        minWidth: 100,
        padding: '2px 4px',
        width: "33vw",
        zIndex: 1301,
    },

    // Texts
    label: {
        color: colors.background.success,
    },
    link: {
        color: colors.font.main,
    },
    title: {
        textAlign: "center",
    },

    // Buttons
    button: {
        borderRadius: 15,
        fontSize: 18,
        fontWeight: "bold",
        height: 70,
        textTransform: "none",
        width: "100%",
    },
    profileMenu: {
        alignItems: 'center',
        background: colors.background.quaternary,
        borderRadius: 15,
        display: 'flex',
        height: 55,
        marginRight: -55,
        padding: '2px 4px',
        width: 200,
    },
    profileMenuAvatar: {
        alignItems: 'center',
        display: 'flex',
        height: 70,
        justifyContent: 'center',
        outline: `solid 6px ${colors.background.primary}`,
        outlineOffset: -2,
        width: 70,
    },
};