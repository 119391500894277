import { useEffect, useRef } from 'react';
import { Interaction } from "../../api/";
import { colors } from "../../theme/";
import { ShowTextField } from "../ShowTextField";
import { ChatBubble } from "./ChatBubble";

// Interfaces
interface ChatProps {
    activeInteractionId?: number;
    interactions: Interaction[];
    setInteractions: React.Dispatch<React.SetStateAction<Interaction[]>>;
};

/**
 * Chat component
 */
export const Chat = ({ activeInteractionId, interactions, setInteractions }: ChatProps) => {
    // Hooks
    const messagesEndRef = useRef<HTMLDivElement>(null);

    // useEffects - Scroll to bottom
    useEffect(() => scrollToBottom(), [interactions]);

    // Handlers - Scroll to bottom
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" } as ScrollIntoViewOptions);
    };

    // Early return
    if (!interactions.length) return null;

    return (
        <ShowTextField
            style={{
                alignItems: "flex-start",
                backgroundColor: colors.background.primary,
                height: "100%",
                maxHeight: 300,
                overflow: "auto",
            }}
            innerStyle={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                width: "100%",
            }}>
            {interactions.map((interaction, index) => (
                <div key={`group-${index}`}>
                    {activeInteractionId && interaction.id === activeInteractionId && <div ref={messagesEndRef} />}
                    <ChatBubble
                        author="player"
                        interaction={interaction}
                        key={`question-${index}`}
                        setInteractions={setInteractions}
                    />
                    <ChatBubble
                        author="Alfred"
                        interaction={interaction}
                        key={`answer-${index}`}
                        setInteractions={setInteractions}
                    />
                </div>
            ))}
            {!activeInteractionId && <div ref={messagesEndRef} />}
        </ShowTextField>
    );
};