import { Avatar, Box, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { GetUserResponse, UserProfile } from "../../../../api/";
import profilePicture from "../../../../medias/profilePicture.svg";
import { colors, styles } from "../../../../theme/";
import { ProfileMenuModal } from "./ProfileMenuModal";

/** 
 * Profile menu component
 */
export const ProfileMenu = ({ userData }: { userData: GetUserResponse; }) => {
    const profile: UserProfile | undefined = userData.profiles ? userData.profiles[0] : undefined;
    const hasProfile: boolean = profile !== undefined;
    const isExternalApprover: boolean = profile?.external_approver ?? false;
    const isInternalApprover: boolean = profile?.internal_approver ?? false;

    // useStates
    const [profileMenuModalOpen, setProfileMenuModalOpen] = useState<boolean>(false);

    return (
        <>
            <Box style={{
                alignItems: 'center',
                cursor: "pointer",
                display: 'flex',
                justifyContent: 'center',
            }} onClick={() => setProfileMenuModalOpen(!profileMenuModalOpen)}>
                {profile &&
                    <Paper style={styles.profileMenu}>
                        <Typography style={{ flex: 1, color: colors.font.primary, paddingLeft: 25, paddingRight: 65 }} noWrap>{profile.nickname}</Typography>
                    </Paper>
                }
                <Avatar
                    alt={profile ? profile.nickname : undefined}
                    src={profile ? (profile.profile_image ?? profilePicture) : undefined}
                    style={styles.profileMenuAvatar}
                />
                <ProfileMenuModal
                    handleClose={() => setProfileMenuModalOpen(false)}
                    hasProfile={hasProfile}
                    isExternalApprover={isExternalApprover}
                    isInternalApprover={isInternalApprover}
                    open={profileMenuModalOpen}
                />
            </Box>
        </>
    );
};