import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
    Box,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
    useTheme,
} from "@mui/material";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../../api';
import { Payment, Price, SubscriptionStatus, _Product } from '../../api/';
import {
    Group,
    InputSelect,
    ShowTextField,
    showComplexSnackbarDispatcher,
    showSnackbar,
} from "../../components/";
import { colors, styles } from "../../theme";
import { CriticalError } from "../CriticalError";
import { Loading } from "../Loading";
import { Page } from "../Page";
import {
    SubscribeButton,
    UnsubscribeButton,
} from './components/';

// Interfaces
interface Column {
    id: 'status' | 'amount' | 'created';
    label: string;
}

/**
 * Table pagination actions 
 */
const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange } = props;

    // Hooks
    const theme = useTheme();

    // Handlers
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => onPageChange(event, page - 1);
    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => onPageChange(event, 0);
    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => onPageChange(event, page + 1);

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page"            >
                {theme.direction === 'rtl'
                    ? <LastPageIcon style={{ color: colors.font.main }} />
                    : <FirstPageIcon style={{ color: colors.font.main }} />
                }
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page"            >
                {theme.direction === 'rtl'
                    ? <KeyboardArrowRight style={{ color: colors.font.main }} />
                    : <KeyboardArrowLeft style={{ color: colors.font.main }} />
                }
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page"            >
                {theme.direction === 'rtl'
                    ? <KeyboardArrowLeft />
                    : <KeyboardArrowRight />
                }
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page"            >
                {theme.direction === 'rtl'
                    ? <FirstPageIcon style={{ color: colors.font.main }} />
                    : <LastPageIcon style={{ color: colors.font.main }} />
                }
            </IconButton>
        </Box>
    );
};

/**
 * Product page
 */
export const Product = () => {
    const columns: readonly Column[] = [
        { id: 'status', label: 'Estado do pagamento' },
        { id: 'amount', label: 'Valor pago (R$)' },
        { id: 'created', label: 'Realizado em' },
    ];

    // Hooks
    const navigate = useNavigate();
    const params = useParams();

    // useStates
    const [criticalError, setCriticalError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [pricesLabels, setPricesLabels] = useState<{ id: string; label: string; }[]>([]);
    const [priceLabel, setPriceLabel] = useState<string>("");
    const [priceId, setPriceId] = useState<string>("");
    const [product, setProduct] = useState<_Product | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | undefined>(undefined);

    // useMemos - Get token
    const productId = useMemo(() => params.product, [params.product]);
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // useEffects - Get payment methods
    useEffect(() => {
        const getSubscriptionStatus = async (token: string, provider: string, product: string) => {
            try {
                const [response, statusOk] = await apiClient.getSubscriptionStatus(token, provider, product);

                if (statusOk && response.result) setSubscriptionStatus(response.result);

            } catch (error) {
                setCriticalError(true);
                showSnackbar({ message: String(error), variant: "error" });
            } finally {
                await new Promise(resolve => setTimeout(resolve, 1000));
                setLoading(false);
            }
        };

        if (!productId) return;
        if (!token || !provider) { navigate("/"); return; }

        getSubscriptionStatus(token, provider, productId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffects - Get prices
    useEffect(() => {
        const getPrices = async (productId: string) => {
            try {
                const [response, statusOk] = await apiClient.getPrices(productId);

                if (statusOk && response.result) {
                    setPricesLabels(response.result.map((_: Price) => {
                        return {
                            id: _.id,
                            label: `$${_.unit_amount} per ${_.interval}`,
                        };
                    }));
                } else {
                    showComplexSnackbarDispatcher(response as { [key: string]: string[]; });
                }

            } catch (error) {
                setCriticalError(true);
                showSnackbar({ message: String(error), variant: "error" });
            } finally {
                await new Promise(resolve => setTimeout(resolve, 1000));
                setLoading(false);
            }
        };

        if (!productId) return;

        getPrices(productId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffects - Set price label
    useEffect(() => {
        if (pricesLabels.length > 0) setPriceId(pricesLabels[0].id);
        if (pricesLabels.length > 0) setPriceLabel(pricesLabels[0].label);
    }, [pricesLabels]);

    // useEffects - Get product
    useEffect(() => {
        const getProduct = async (productId: string) => {
            try {
                const [response, statusOk] = await apiClient.getProduct(productId);

                if (statusOk && response.result) setProduct(response.result);
                else setCriticalError(true);

            } catch (error) {
                setCriticalError(true);
                showSnackbar({ message: String(error), variant: "error" });
            } finally {
                await new Promise(resolve => setTimeout(resolve, 1000));
                setLoading(false);
            }
        };

        if (!productId) return;

        getProduct(productId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffects - Get payments
    useEffect(() => {
        const getPayments = async (token: string, provider: string) => {
            try {
                const [response, statusOk] = await apiClient.getPayments(token, provider);

                if (statusOk && response.result) setPayments(response.result);
                else setPayments([]);

            } catch (error) {
                setCriticalError(true);
                showSnackbar({ message: String(error), variant: "error" });
            } finally {
                await new Promise(resolve => setTimeout(resolve, 1000));
                setLoading(false);
            }
        };

        if (!token || !provider) { navigate("/"); return; }

        getPayments(token, provider);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffects - Loading effect
    useEffect(() => { setTimeout(() => setLoading(false), 1000); }, []);

    // Handlers
    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    // Early return
    if (criticalError || !productId) return <CriticalError />;

    // Styling - Table
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colors.background.success,
            border: 0,
            color: colors.font.tertiary,
            fontSize: 16,
            fontWeight: "bold",
            textAlign: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            backgroundColor: colors.background.main,
            borderBottom: `1px solid ${colors.font.tertiary}`,
            color: colors.font.main,
            fontSize: 14,
            textAlign: "center",
            transition: "all 1s ease",

            '&:hover ': {
                backgroundColor: colors.background.success,
                color: colors.font.tertiary,
                cursor: "pointer",
                fontWeight: "bold",
            },
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:first-of-type th:first-of-type': { borderTopLeftRadius: 10 },
        '&:first-of-type th:last-child': { borderTopRightRadius: 10 },
    }));
    const StyledTablePagination = styled(TablePagination)(() => ({
        border: 0,
        color: colors.font.main,

        '& .MuiSvgIcon-root': { color: colors.font.main },
        '& .MuiTablePagination-menuItem': { backgroundColor: `${colors.background.main} !important` },
    }));

    return (
        <Page menu needsAuthentication needsProfile needsVerification>
            <Loading show={loading} />
            {subscriptionStatus && product && subscriptionStatus.status === "active" && (
                <Group direction={"column"} style={{ margin: "25px 0px" }} >
                    <Container style={{ ...styles.inPlaceModal }}>
                        <Group direction="column">
                            <Typography style={{
                                fontFamily: "ArchivoBlack",
                                fontSize: 36,
                                textAlign: "center",
                            }}>
                                Seu plano {subscriptionStatus.cancel_at_period_end ? "irá expirar!" : "está pronto!"}
                            </Typography>
                            <ShowTextField style={{ justifyContent: "flex-start" }}>
                                <Typography>Nome do plano: {product.name}</Typography>
                                <Typography>Iniciado em: {subscriptionStatus.subscription.current_period_start}</Typography>
                                <Typography>{subscriptionStatus.cancel_at_period_end ? "Acaba em" : "Renova em"}: {subscriptionStatus.subscription.current_period_end}</Typography>
                                {!subscriptionStatus.cancel_at_period_end && (
                                    <Typography>
                                        Valor de renovação: ${subscriptionStatus.subscription.price.unit_amount} per {subscriptionStatus.subscription.price.interval}
                                    </Typography>
                                )}
                            </ShowTextField>
                            <ShowTextField
                                innerStyle={{
                                    fontFamily: "ArchivoBlack",
                                    fontSize: 24,
                                    textAlign: "center",
                                }}
                                style={{
                                    backgroundColor: subscriptionStatus.cancel_at_period_end ? colors.background.warning : colors.background.success,
                                    padding: "24px 0px",
                                }}
                            >
                                {product.name && (
                                    <Typography style={{
                                        color: subscriptionStatus.cancel_at_period_end ? colors.font.main : colors.font.tertiary,
                                        fontFamily: "ArchivoBlack",
                                        fontSize: 36,
                                        textAlign: "center",
                                    }}>
                                        {subscriptionStatus.cancel_at_period_end ? "Será cancelado" : "ATIVO"}
                                    </Typography>
                                )}
                            </ShowTextField>
                        </Group>
                        {!subscriptionStatus.cancel_at_period_end && (
                            <UnsubscribeButton productId={productId} productName={product.name} />
                        )}
                    </Container>
                </Group>
            )}
            {subscriptionStatus && product && subscriptionStatus.status !== "active" && (
                <Group direction={"column"} style={{ margin: "25px 0px" }} >
                    <Container style={{ ...styles.inPlaceModal }}>
                        <Group direction="column">
                            <Typography style={{
                                fontFamily: "ArchivoBlack",
                                fontSize: 36,
                                textAlign: "center",
                            }}>
                                Você está quase lá!
                            </Typography>
                            <ShowTextField
                                innerStyle={{
                                    fontFamily: "ArchivoBlack",
                                    fontSize: 24,
                                    textAlign: "center",
                                }}
                                style={{ padding: "24px 0px" }}
                            >
                                {product.name && (
                                    <Typography style={{
                                        fontFamily: "ArchivoBlack",
                                        fontSize: 36,
                                        textAlign: "center",
                                    }}>
                                        {product.name}
                                    </Typography>
                                )}
                            </ShowTextField>
                            {product.description && (
                                <Typography style={{
                                    fontFamily: "PublicSansRegular",
                                    fontSize: 22,
                                    marginTop: 25,
                                    padding: "25px 25px",
                                    textAlign: "center",
                                }}>
                                    {product.description}
                                </Typography>
                            )}
                            {pricesLabels.length > 0 && <InputSelect fields={pricesLabels.map(_ => _.label)} placeholder='Choose your plan' setValue={setPriceLabel} value={priceLabel} />}
                        </Group>
                        <SubscribeButton priceId={priceId} productId={productId} productName={product.name} />
                    </Container>
                </Group>
            )}
            {payments.length > 0 && (
                <Container style={{ ...styles.inPlaceModal, maxWidth: undefined, justifyContent: "flex-start" }}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <StyledTableRow>
                                    {columns.map((column) => (
                                        <StyledTableCell key={column.id}>
                                            {column.label}
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {payments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((payment) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={payment.created}>
                                            {columns.map((column) => {
                                                const value = payment[column.id];

                                                return (
                                                    <StyledTableCell key={column.id}>
                                                        {value}
                                                    </StyledTableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {payments.length > 5 && (
                        <StyledTablePagination
                            ActionsComponent={TablePaginationActions}
                            colSpan={3}
                            count={payments.length}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10]}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                        />
                    )}
                </Container>
            )}
        </Page >
    );
};